<template>
<div id="PostList" class="animated fadeIn slower">

<v-container fluid>

<div class="titleDiv px-5">
  <p class="display-1">Articles</p>
</div>

<article class="post-container">
<v-row height="100%" class="mt-5" v-show="GetArticles">
<v-col cols="12" sm="12" md="4" class="mb-5 pb-5" v-for="article in GetArticles" :key="article.slug" height="100%">
<v-fade-transition>
  <v-card class="elevation-5 grey lighten-3 animated fadeIn slower" height="100%">

    <v-img height="200px" class="animated fadeIn slower" aspect-ratio="1" :src="article.imageUrl">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <div class="card-strip elevation-5">
      <v-card-subtitle class="py-3 subtitle"> Published : {{article.DOE}}</v-card-subtitle>
    </div>

    <v-card-text class="subtitle-1 card-outter">
      <p class="py-3">{{article.title}}</p>
    </v-card-text>

    <v-card-actions class="card-actions">
      <v-btn class="action-btn elevation-5" @click="$router.push('/articleoverview/' + article.slug)">
        Read<v-icon class="ml-2" dark>mdi-chevron-double-right</v-icon>
      </v-btn>
    </v-card-actions>

  </v-card>
</v-fade-transition>
</v-col>
</v-row>

<div v-show="!GetArticles" class="my-5" height="100%">
    <v-row>
        <v-col cols="12" sm="12" md="4" v-for="n in 6" :key="n">
        <v-skeleton-loader class="mx-auto" type="card, list-item, actions">
        </v-skeleton-loader>
        </v-col>
    </v-row>
  </div>


</article>

</v-container>
</div>

</template>

<script>
export default {
    name : 'PostList',

    metaInfo: {
        title: 'Posts',
    },

    created(){window.scrollTo(0, 0)},

    computed: {
      GetArticles(){
        let data = this.$store.getters.GetAllPosts;
        return data != '' ? data : null;
      },
    }
}
</script>

<style scoped>
#PostList{
  padding: 3vw;
  padding-top: 2rem !important;
  color: #eee !important;
}
.post-container{
  padding-left: 1vw;
  padding-right: 1vw;
}
.titleDiv{
  border-left: 10px solid #eee;
  margin-bottom: 2rem;
}
.card-strip{
  background-color: #1A3051 !important;
}
.card-strip .subtitle{
  color: #ddd !important;
}
.card-outter {
  position: relative;
  padding-bottom: 60px;
  color: #222 !important;
}
.card-actions {
  position: absolute;
  bottom: 0;
  padding: 20px 13px
}
.card-actions .action-btn {
  background-color: #1A3051 !important;
  border: 1px solid #ddd !important;
  color: #ddd !important;
}
</style>